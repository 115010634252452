<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Add New Keranjang
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Kode Produk 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlkode_produk" v-model.trim="formData.kode_produk"  label="Kode Produk" type="text" placeholder="Enter Kode Produk"      
                                                    class=" w-full" :class="getErrorClass('kode_produk')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('kode_produk')" class="p-error">{{ getFieldError('kode_produk') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Nama Produk 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlnama_produk" v-model.trim="formData.nama_produk"  label="Nama Produk" type="text" placeholder="Enter Nama Produk"      
                                                    class=" w-full" :class="getErrorClass('nama_produk')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('nama_produk')" class="p-error">{{ getFieldError('nama_produk') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Qty 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlqty" v-model.trim="formData.qty"  label="Qty" type="text" placeholder="Enter Qty"      
                                                    class=" w-full" :class="getErrorClass('qty')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('qty')" class="p-error">{{ getFieldError('qty') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Harga 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlharga" v-model.trim="formData.harga"  label="Harga" type="text" placeholder="Enter Harga"      
                                                    class=" w-full" :class="getErrorClass('harga')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('harga')" class="p-error">{{ getFieldError('harga') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Total 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrltotal" v-model.trim="formData.total"  label="Total" type="text" placeholder="Enter Total"      
                                                    class=" w-full" :class="getErrorClass('total')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('total')" class="p-error">{{ getFieldError('total') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Status Pembayaran 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlstatus_pembayaran" v-model.trim="formData.status_pembayaran"  label="Status Pembayaran" type="text" placeholder="Enter Status Pembayaran"      
                                                    class=" w-full" :class="getErrorClass('status_pembayaran')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('status_pembayaran')" class="p-error">{{ getFieldError('status_pembayaran') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Kode Transaksi 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlkode_transaksi" v-model.trim="formData.kode_transaksi"  label="Kode Transaksi" type="text" placeholder="Enter Kode Transaksi"      
                                                    class=" w-full" :class="getErrorClass('kode_transaksi')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('kode_transaksi')" class="p-error">{{ getFieldError('kode_transaksi') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Foto Produk 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlfoto_produk" v-model.trim="formData.foto_produk"  label="Foto Produk" type="text" placeholder="Enter Foto Produk"      
                                                    class=" w-full" :class="getErrorClass('foto_produk')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('foto_produk')" class="p-error">{{ getFieldError('foto_produk') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Jenis Pengiriman 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrljenis_pengiriman" v-model.trim="formData.jenis_pengiriman"  label="Jenis Pengiriman" type="text" placeholder="Enter Jenis Pengiriman"      
                                                    class=" w-full" :class="getErrorClass('jenis_pengiriman')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('jenis_pengiriman')" class="p-error">{{ getFieldError('jenis_pengiriman') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Biaya Pengiriman 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlbiaya_pengiriman" v-model.trim="formData.biaya_pengiriman"  label="Biaya Pengiriman" type="text" placeholder="Enter Biaya Pengiriman"      
                                                    class=" w-full" :class="getErrorClass('biaya_pengiriman')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('biaya_pengiriman')" class="p-error">{{ getFieldError('biaya_pengiriman') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlno_invoice"  ref="ctrlno_invoice" v-model="formData.no_invoice" type="hidden" />
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Item Id 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlitem_id" v-model.trim="formData.item_id"  label="Item Id" type="text" placeholder="Enter Item Id"      
                                                    class=" w-full" :class="getErrorClass('item_id')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('item_id')" class="p-error">{{ getFieldError('item_id') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Simpan" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required } from 'src/services/validators';
	import { useApp } from 'src/composables/app.js';
	import { useAddPage } from 'src/composables/addpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		pageStoreKey: {
			type: String,
			default: 'KERANJANG',
		},
		pageName : {
			type : String,
			default : 'keranjang',
		},
		routeName : {
			type : String,
			default : 'keranjangadd',
		},
		apiPath : {
			type : String,
			default : 'keranjang/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Simpan",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: "Create Record",
		},
		msgAfterSave: {
			type: String,
			default: "Data berhasil ditambahkan",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	//lines
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	
	const formDefaultValues = {
		kode_produk: "", 
		nama_produk: "", 
		qty: "", 
		harga: "", 
		total: "", 
		status_pembayaran: "", 
		kode_transaksi: "", 
		foto_produk: "", 
		jenis_pengiriman: "NULL", 
		biaya_pengiriman: "NULL", 
		no_invoice: "", 
		item_id: "NULL", 
	};
	
	const formData = reactive({ ...formDefaultValues });
	
	//vuelidate form validation rules
	const rules = computed(() => {
		return {
			kode_produk: {  },
			nama_produk: {  },
			qty: {  },
			harga: {  },
			total: {  },
			status_pembayaran: {  },
			kode_transaksi: {  },
			foto_produk: {  },
			jenis_pengiriman: {  },
			biaya_pengiriman: {  },
			no_invoice: {  },
			item_id: {  }
		}
	});
	
	const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
	
	// event raised before submit form
	function beforeSubmit(){
		return true;
	}
	
	// event raised after form submited
	function afterSubmit(response) { 
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/keranjang`);
		}
	}
	
	const {  saving, pageReady, } = toRefs(page.state);
	
	const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Add New Keranjang";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
